import "../src/shared/wrap_ready.js";
import Cookie from "../src/shared/cookie";
// import Header from "../src/samir/components/header";

window.Samir = {};

window.addEventListener("DOMContentLoaded", () => {
  window.Samir.Cookie = new Cookie();

  // window.Samir.Header = new Header({ selector: ".nav-bar" });
  // window.Samir.Header.start();
});

import "../../whitelabels/samir/assets/javascripts/application.js";
