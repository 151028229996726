var userLang = navigator.language || navigator.userLanguage;
userLang = userLang.substring(0, 2).toLowerCase();
if (!["en", "fr"].includes(userLang)) {
  userLang = "fr";
}
window.Parsley.setLocale(userLang);

if ($("#new_user").length != 0) {
  $("#new_user").parsley();
}
