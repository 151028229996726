const accordions = document.querySelectorAll(".filter-group");
const breakpoint = 768;

if (window.innerWidth <= breakpoint) {
  close_all_accordions(accordions);
  initialize_accordions(accordions);
} else {
  remove_accordions(accordions);
}

window.addEventListener("resize", () => {
  if (window.innerWidth <= breakpoint) {
    close_all_accordions(accordions);
    initialize_accordions(accordions);
  } else {
    open_all_accordions(accordions);
    remove_accordions(accordions);
  }
});

function initialize_accordions(accordions) {
  accordions.forEach((accordion) => {
    let accordion_title = accordion.querySelector(".filter-title");
    let accordion_content = accordion.querySelector(".filter-options");
    accordion_content.style.maxHeight = "0px";
    accordion_title.addEventListener("click", toggleAccordion);
  });
}

function remove_accordions(accordions) {
  accordions.forEach((accordion) => {
    let accordion_title = accordion.querySelector(".filter-title");
    accordion_title.removeEventListener("click", toggleAccordion);
  });
}

function toggleAccordion() {
  const accordion = this.parentElement;
  const accordion_content = accordion.querySelector(".filter-options");
  if (accordion_content.style.maxHeight != "0px") {
    accordion_content.style.maxHeight = "0px";
    accordion_content.parentElement.style.paddingBottom = "0px";
  } else {
    close_all_accordions(accordions);
    accordion_content.style.maxHeight = accordion_content.scrollHeight + "px";
    accordion_content.parentElement.style.paddingBottom = "22px";
  }
}

function open_all_accordions(elements) {
  elements.forEach((accordion) => {
    let accordion_content = accordion.querySelector(".filter-options");
    accordion_content.style.maxHeight = accordion_content.scrollHeight + "px";
    accordion_content.parentElement.style.paddingBottom = "22px";
  });
}

function close_all_accordions(elements) {
  elements.forEach((accordion) => {
    let accordion_content = accordion.querySelector(".filter-options");
    accordion_content.style.maxHeight = "0px";
    accordion_content.parentElement.style.paddingBottom = "0px";
  });
}
